<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5 text-light font-weight-bold" style="font-size: 3.5vw">
            Let’s start playing <br> the game to better <br> your future
          <!-- <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <!-- Brand logo-->
        <b-link style="position: absolute; top:0; right: 0" to="dashboard">
          <img src="@/assets/images/logo/logo.png" alt="" class="logo">
        </b-link> 
        <b-col
          sm="8"
          md="6"
          lg="8"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-light"
          >
            Reset Password
          </b-card-title>
          <!-- <b-card-text class="mb-2 text-muted">
            Your new password must be different from previously used passwords
          </b-card-text> -->

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
                class="light-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="form.password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge text-light custom-form-control"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <!-- <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append> -->
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
                class="light-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="form.confirm_password"
                      :type="password2FieldType"
                      class="form-control-merge text-light custom-form-control"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <!-- <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append> -->
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
                class="rounded-pill shadow-none mt-3"
                style="background-color: #E84185 !important; border-color: #E84185 !important"
              >
                Confirm
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}" style="color: #E84185">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p> -->
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      form:{
        confirm_password: '',
        password: '',
      },
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    token(){
      return this.$route.params.token ? this.$route.params.token : null
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // this.form.email = localStorage.getItem('userEmail')
          this.form.token = this.token
          const payload = {query: this.form }
          this.$store.dispatch( 'users/resetPassword' , payload ).then(res=>{
            this.$router.push({name:'auth-login'})
          })
          .catch(error => {
              if (error.response) {
                this.$swal({
                  icon: 'error',
                  title: `<h4>${error.response.data.message}</h4>`,
                  showConfirmButton: true,
                  allowOutsideClick: true,
                  confirmButtonColor: '#E84185',
                });
              }
          });
          
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
*{
  font-family: "Inter", sans-serif !important;
}
.logo{
  width: 200px;
}
@media (max-width: 992px) {
  .logo{
    width: 100px;
  }
  .auth-inner{
    display: flex !important;
    justify-content: center !important;
  }
}

.auth-wrapper{
  background: url('../../../assets/images/banner/dark_bakground.png') no-repeat;
  background-size: cover;
}
.auth-bg{
  background-color: #5a5a5a32 !important;
  color: #fff !important
}

// .form-control{
//   background-color:rgba(0,0,0,0) !important;
//   border:1px solid #888 !important;
//   height: 50px;
// }
.inner-addon { 
    position: relative; 
}
.inner-addon .feather-mail,.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}

.custom-form-control {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #888 !important;
  height: 50px;
}

.left-addon input  { padding-left:  40px; color: #fff !important }
.password-addon {padding-left:  40px !important; border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important; color: #fff !important}

</style>
